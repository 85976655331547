.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .content {
    margin-top: 15px;
  }
}

@media (max-width: 768px) {
  .App-header {
    font-size: 5vmin; /* Smaller font size for mobile */
  }

  .content {
    padding-top: 40px; /* Less padding for the mobile navbar */
  }
}
.small-icon {
  font-size: 4rem; /* You can set this to any size you want */
}
.medium-icon {
  font-size: 4rem; /* You can set this to any size you want */
}
.large-icon {
  font-size: 6rem; /* You can set this to any size you want */
}
.extra-large-icon {
  font-size: 8rem; /* You can set this to any size you want */
}
.font-weight-light{
  font-weight: 100 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
