/* ContactUs.css */

.contact-us-container {
  max-width: 800px;
  margin: auto;
  padding: 50px 20px;
  font-family: Arial, sans-serif; /* Use your preferred font here */
  margin-top:80px;
}

.contact-us-title {
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.image-container {
  text-align: center;
  margin-bottom: 30px;
}

.contact-us-form-section {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-us-form {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Spacing between form fields */
}

.form-field {
  display: flex;
  gap: 10px; /* Spacing between elements in a field */
}

.form-field input,
.form-field select,
.form-field textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.form-field select {
  flex-basis: 30%; /* Adjust the width of the select dropdown */
}

.form-field input[type="number"] {
  flex-basis: 70%; /* Adjust the width of the input field */
}

.form-field textarea {
  height: 120px;
  resize: vertical; /* Allow vertical resizing */
}

.contact-us-submit-button {
  padding: 10px 15px;
  background-color: #08BF99;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-us-submit-button:hover {
  background-color: #0da889;
}

.contact-us-submit-button:disabled {
  background-color: #ccc; /* Example color for disabled state */
  cursor: not-allowed;
  /* You can add more styling as needed */
}

.toast-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 15px;
  background-color: #08BF99;
  color: white;
  border-radius: 5px;
  z-index: 1000;
}

.toast-container button {
  margin-left: 10px;
  background: none;
  color: white;
  border: none;
  cursor: pointer;
}


@media (max-width: 768px) {
  .contact-us-container {
    margin-top: 10px;
  }
}
