/* Background Colors */
.bg-primary-green {
    background-color: #08BF99;
}

.bg-grey-light {
    background-color: #CED2CC;
}
.bg-grey {
    background-color: #B5B5B1;
}
.bg-secondary {
    background-color: #9A9996;
}

.bg-success {
    background-color: #28a745;
}

.bg-danger {
    background-color: #dc3545;
}

.bg-warning {
    background-color: #ffc107;
}

.bg-info {
    background-color: #17a2b8;
}

.bg-light {
    background-color: #f8f9fa;
}

.bg-dark {
    background-color: #343a40;
}

.bg-pink {
    background-color: #dd00d9;
}

.bg-green {
    background-color: #00990f;

}
/* Font Colors */
.text-green {
    color: #08BF99 !important;
}
.text-primary {
    color: #08BF99 !important;
}

.text-secondary {
    color: #6c757d;
}

.text-success {
    color: #28a745;
}

.text-danger {
    color: #dc3545;
}

.text-warning {
    color: #ffc107;
}

.text-info {
    color: #17a2b8;
}

.text-light {
    color: #f8f9fa;
}
.text-grey-light {
    color: #9A9996;
}

.text-dark {
    color: #343a40;
}

.text-white {
    color: #fff;
}

.text-black {
    color: #000;
}
