/* AboutUs.css */

.about-us-wrapper {
    font-family: 'Arial', sans-serif;
}

.about-us-hero {
    /* background: #005f99; Replace with a desired background */
    /* background-image: url('../assets/diamond-earing-dark.jpg'); */
    background-image: url('../assets/diamondLeave.jpg');
    min-height: 100vh;
    flex-direction: column;
    padding-left: 200px;
    padding-right: 200px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
   
}

.about-us-mission {
    position: relative;
    /* min-height: 100vh; */
    color: #fff;
}

.multi-column-signpost {
    background: #001b2c;
    content: '';
    position: absolute;
    top: -1px;
    height: 55.5%;
    width: 100%;
}

.unparalleled-heading{
    margin-top: 20px;

}
.unparalleled-description{
    margin-top:20px
}
.parallax-background {
    /* Assume you have an appropriate image in your assets */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.about-us-legacy {
    background-color: #f8f9fa;
}

.about-us-expertise .card {
    transition: transform 0.3s ease;
}

.about-us-expertise .card:hover {
    transform: translateY(-10px);
}

.card-deck .card {
    margin-bottom: 2rem;
}


.mission-container {
    position: relative;
    margin-top: 100px;
    /* other styles */
  }
  
  .mission-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 50%; /* Only cover the top half */
    background: #fff; /* Dark navy color */
    z-index: -1; /* Ensure it's behind the content */
  }

  .legacy-container{
    margin-top:10px;
    background-image: url('../assets/diamondBlackTong.jpg');
    background-position: 500px calc(60%); 
    background-color: #000;
    background-size: cover; /* or you could use contain, depending on your needs */
    background-repeat: no-repeat;
    height: 400px; /* Set the desired height */
    width: 100%; /* Set the desired width */
  }
  


  
  .about-us-text-container h1 {
    font-size: 2.5rem; /* Adjust the size as needed */
  }
  
  .about-us-text-container p {
    font-size: 1rem; /* Adjust the size as needed */
    line-height: 1.5; /* For better readability */
  }
  

.about-us-text-container {
    /* Other styles... */
    /* Apply the transform only on desktop views */
    @media (min-width: 992px) { /* This targets devices with a width of 992px and up, which typically includes tablets in landscape mode and desktop displays */
        transform: translateY(-90px);
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .about-us-hero {
        padding: 20px 10px; /* Adjusted padding */
        background-attachment: scroll; /* Disable fixed background for better performance and compatibility */
        background-position: top center; /* Optional: Adjust this if you want to reposition the image */
        padding-bottom: 400px;
        margin-top: 30px;
    }
    
    .about-us-text-container {
        background: rgba(0, 0, 0, 0.5); /* Semi-transparent background for the text container */
        border-radius: 8px; /* Optional: Adds rounded corners to the text container */
        padding: 15px; /* Padding inside the text container */
    }

    .about-us-text-container h1 {
        font-size: 1.5rem; /* Smaller font size for h1 on mobile */
    }

    .about-us-text-container p {
        font-size: 0.9rem; /* Smaller font size for paragraph on mobile */
    }

    .legacy-container{
        max-height: 320px;

    }
    .mission-container::before {
        bottom: 60%; /* You might want to cover less than half on mobile for better aesthetics */
      }
}

  