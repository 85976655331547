.navbar-container {
    transition: background-color 0.3s; /* Smooth transition for background color */
    background-color: #ffffff00; /* White background when scrolled */
  }

  .navbar-container.scrolled {
    background-color: #fff; /* White background when scrolled */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: adds shadow for better visibility */
  }

.logo-verified-vdv{
    max-width: 40%;
    height: auto;
}

.text-black {
    color: #000 !important;
}

.text-white {
    color: #fff !important;
}


.navbar-logo img {
    height: 50px; /* Adjust size as needed */
    margin-top: 10px;
    margin-bottom: 10px;
}

.about-us-nav{
 font-weight: bold !important;
}



/* Responsive adjustments for mobile */
@media (max-width: 768px) {

}
@media (max-width: 768px) {
    .navbar-logo img {
        height: 30px; /* Smaller logo on mobile */
    }
    .navbar-brand, .navbar-toggler {
      align-self: center;
    }
  
    .navbar-collapse {
      flex-grow: 0; /* Prevents the navbar from stretching */
      text-align: end;
      padding-right: 10px;
    }
  }

/* NavBar.css */
