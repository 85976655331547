/* src/components/Home.css */
.land-container {
    color: white;
    padding-bottom: 50px;
    min-height: 100vh; /* To ensure the container takes full viewport height */
    @media (min-width: 767px) { /* This targets devices with a width of 992px and up, which typically includes tablets in landscape mode and desktop displays */
        margin-top: 120px;
    }
}



.horizontal-line-container{
    margin-left: 200px;
    margin-right: 200px;
  }

.home-welcome-container{
    /* padding-top: 140px; */
    border: #08BF99;
    border-color: #08BF99;
    border-width: 1px;
}
  
  .home-welcome-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 50%; /* Only cover the top half */
    z-index: -1; /* Ensure it's behind the content */
   
  }
.join-container {
    color: white;
    padding-top: 50px; /* Adjust to the height of your navbar */
    padding-bottom: 50px;
}

.home-content {
    max-width: 960px; /* Or the max-width you desire */
    margin: auto;
    padding: 2em;
    text-align: center;
}

.home-title {
    font-size: 3.5em; /* Adjust to match your design */
    margin-bottom: 20px;
}
.home-title-secondary {
    font-size: 2.5em; /* Adjust to match your design */
    margin-bottom: 20px;
}

.home-description {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.home-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; /* Space between buttons */
    margin-bottom: 20px;
}

.home-button {
    background-color: #08BF99; /* Adjust to match your design */
    padding: 10px 20px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: white;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.home-button img {
    margin-right: 8px;
    height: 24px; /* Adjust to match your design */
}

.home-button:hover {
    background-color: #08BF99; /* Darker shade for hover state */
}

.home-features-link {
    color: white;
    text-decoration: underline;
}

.join-link {
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    display: inline-block;
    margin-top: 2rem;
}

.join-link:hover {
    background-color: #08BF99;
}

.large-icon {
    font-size: 3rem; /* Larger icons */
    color: white;
}

/* Styling for feature sections under each icon */
.row .col-md-3 {
    margin-bottom: 1rem;
}

.row .col-md-3 h4 {
    color: white;
    font-size: 1.2rem; /* Title size for features */
}

.row .col-md-3 p {
    color: white;
    font-size: 1rem; /* Text size for features */
    margin-top: 0.5rem;
}
.services-tabs button {
    transition: all 0.3s ease;
}

.services-tabs button:hover {
    transform: scale(1.05);
}

.tab-content {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.kyc-content, .kyb-content {
    animation: fadeIn 0.5s ease-in-out;
}


  
.horizontal-line-2{
    width: 100%;
    border: 1px solid #9A9996; /* Line style */
    border-radius: 100px;
    margin-bottom: 1rem;
    margin-top: 20px; /* Space above the line */
    margin-bottom: 20px; /* Space below the line */

}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
/* Responsive adjustments */
@media (max-width: 768px) {
    .horizontal-line-container {
        margin-left: 20px;
        margin-right: 20px;
      }
    .home-content {
        padding: 1em;
    }

    .home-title {
        font-size: 2em;
    }
    .home-title-secondary {
        font-size: 1.5em;
    }

    .home-buttons {
        flex-direction: column;
    }

    .home-button {
        width: 100%;
        margin: 10px 0;
    }

    .large-icon {
        font-size: 2rem; /* Smaller icons on mobile */
    }

    .row .col-md-3 {
        margin-bottom: 1.5rem;
    }

    .row .col-md-3 h4 {
        font-size: 1rem; /* Smaller title size on mobile */
    }

    .row .col-md-3 p {
        font-size: 0.9rem; /* Smaller text size on mobile */
    }
}
