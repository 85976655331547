/* FeatureBoxes.css */

.feature-box {
    /* text-align: center; */
    margin-bottom: 30px;
}

.img-responsive {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
}


.top-down-background{
    background: #000;
    content: '';
    /* position: absolute; */
    top: -1px;
    height: 5.5%;
    width: 100%;
}
.hr-line-container{
    /* margin-left: 200px; */
    /* margin-right: 200px; */
    margin-top: 40px;
    margin-bottom: 40px;

  }
.hr-line-2{
    width: 100%;
    border: 1px solid #9A9996; /* Line style */
    border-radius: 100px;
    margin-bottom: 1rem;
    margin-top: 20px; /* Space above the line */
    margin-bottom: 20px; /* Space below the line */

}

/* Ensure that the total column count does not exceed 12 for the row */
@media (min-width: 992px) {
    .offset-lg-1 {
        margin-right: 8.333333%; /* Equal to one column in a 12-column grid */
    }
}

/* Adjustments for smaller screens */
@media (max-width: 991px) {
    .feature-box {
        margin-bottom: 20px;
    }
    .offset-lg-1 {
        margin-right: 0;
    }
}
