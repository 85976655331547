/* DualFeatureBox.css */

.feature-row {
    margin-bottom: 4rem; /* Space between rows */
}

.feature-row .col-md-6 {
    padding: 1rem; /* Padding for spacing */
}

.title-underline {
    width: 50px; /* Length of the underline */
    border: 2px solid #000; /* Line style */
    margin-bottom: 1rem; /* Space between line and text */
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
    .title-underline {
        width: 30px; /* Shorter line on smaller screens */
    }

    .feature-row {
        text-align: center; /* Center content for small screens */
    }

    .feature-row .col-md-6 {
        padding: 0; /* Remove padding for small screens */
    }

    .feature-row img {
        margin-bottom: 1rem; /* Space between image and text on small screens */
    }

    /* Adjust the order of elements for small screens */
    .order-sm-1 {
        order: 1;
    }

    .order-sm-2 {
        order: 2;
    }
}
